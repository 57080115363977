import React, { useState } from "react"
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap"
import classnames from "classnames"

import "./tabs.scss"

const Tabs = ({
  content,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab)
  }

  const titles = ["Angebot", "Aktuell", "Hofeigenes Fleisch bestellen"]

  return (
    <Container className="tabs-wrapper">
      <Nav className="navigation" tabs>
        {titles.map((title, idx) => {
          return (
            <NavItem key={idx}>
              <NavLink
                className={classnames({ active: activeTab === idx })}
                onClick={() => { toggle(idx); }}
              >
                {title}
            </NavLink>
          </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {content.map((item, idx) => {
          return (
            <TabPane tabId={idx}>
              <Row>
                <Col sm="12">
                  {item}
                </Col>
              </Row>
            </TabPane>
          )
        })}
      </TabContent>
    </Container>
  );
}

export default Tabs
