import React from "react"
import { Container, Row, Col } from "reactstrap"


import "./about.scss"

const Top = () => {
  return (
    <div className="about pad">
      <Container>
        <Row>
          <Col md="12" lg="12">
            <section className="about-content">
              
              <p className="about-description text-secondary ">
              Sie interessieren sich für Gutes aus der Region?<br></br> 
              Nebst eigenen Hofprodukten bieten wir saisonale Spezialitäten von regionalen Produzenten und Produzentinnen an: Für den Wocheneinkauf, für ein Geschenk oder einfach, um neue Delikatessen zu entdecken. «Ausflügler & Ausflüglerinnen», Wanderer und Wanderinnen finden auch Leckereien für unterwegs oder zum sofort geniessen. 
              <br></br><br></br>Gleichzeitig kann die historische Trotte für Workshops und kulturelle Anlässe und für private Zwecke mit gastronomischem Angebot gemietet werden.
              </p>
              
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Top
