import React from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { ToastContainer } from "react-toastify"

// ---------- Themes colors ----------
import "../themes/green.scss"
import "./index.scss"

// ---------- Components ----------
import { Container, Row, Col } from "reactstrap"
import Hero from "../components/hero"
import Top from "../components/top"
import Tabs from "../components/tabs"
import GridImages from "../components/gridImages"
import AktuellTab from "../components/AktuellTab"
import BestellformularTab from "../components/BestellformularTab"
import { contentOptions } from "../utils/parser"

import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"

const IndexPage = ({ data: {
  allContentfulBilderHofladen,
  allContentfulHofladenTexteAngebot,
  allContentfulHofladenTexteAktuell,
  allContentfulHofladenGalerieAktuell,
  allContentfulHofladenBestellformularTexte,
  allContentfulHofladenArtikelBestellformular,
  site,
} }) => {
  const { fullTitle, description } = site.siteMetadata
  
  return (
    <div className="test-q">
      <Hero title={fullTitle} description={description} />
      <Top/>

      <ToastContainer />
      <Tabs
          content={[
            <>
              <Container>
                <Row>
                {/* style={{ margin: '0 2.5%'}} */}
                  <Col >
                  <GridImages images={allContentfulBilderHofladen.edges[0].node.bilder} />
                          <br/>
                    {allContentfulHofladenTexteAngebot?.edges?.[0].node?.angebotTitle && (
                      <h2>{allContentfulHofladenTexteAngebot.edges[0].node?.angebotTitle}</h2>
                    )}
                    <div className="subtitle">
                    {allContentfulHofladenTexteAngebot?.edges?.[0].node?.angebotText?.raw &&
                      documentToReactComponents(JSON.parse(allContentfulHofladenTexteAngebot.edges[0].node.angebotText.raw), contentOptions)}
                        </div>
                        <br/>
                    {/* {allContentfulHofladenTexteAngebot?.edges?.[0].node?.angebotTitle2 && (
                      <h2>{allContentfulHofladenTexteAngebot.edges[0].node?.angebotTitle2}</h2>
                    )}

                    {allContentfulHofladenTexteAngebot?.edges?.[0].node?.angebotText2?.raw &&
                      documentToReactComponents(JSON.parse(allContentfulHofladenTexteAngebot.edges[0].node.angebotText2.raw), contentOptions)}
                  */}
                  </Col>
                </Row>

                <div style={{height:'50px'}}></div>
              </Container>
              
            </>,
            <>
              <AktuellTab
                allContentfulHofladenTexteAktuell={allContentfulHofladenTexteAktuell}
                allContentfulHofladenGalerieAktuell={allContentfulHofladenGalerieAktuell}
              />
            </>,
            <>
              <BestellformularTab
                allContentfulHofladenBestellformularTexte={allContentfulHofladenBestellformularTexte}
                allContentfulHofladenArtikelBestellformular={allContentfulHofladenArtikelBestellformular}
                
              />
              
            </>         
          ]}
        />
    </div>
  )
}

export const projectsQuery = graphql`
  query FeaturedProjectsQuery {
    allContentfulBilderHofladen {
      edges {
        node {
          id
          bilder {
            id
            file {
              url
            }
          }
        }
      }
    }
    allContentfulHofladenTexteAngebot {
      edges {
        node {
          angebotTitle
          angebotText {
            raw
          }
        }
      }
    }
    allContentfulHofladenTexteAktuell {
      edges {
        node {
          aktuellTitle
          aktuellTitle2
          aktuellTitle3
          aktuellText {
            raw
          }
          aktuellText2 {
            raw
          }
          aktuellText3 {
            raw
          }
        }
      }
    }
    allContentfulHofladenGalerieAktuell {
      edges {
        node {
          title
          beschreibung {
            raw
          }
          bild {
            file {
              url
              fileName
            }
          }
        }
      }
    }
    
    allContentfulHofladenBestellformularTexte {
      edges {
        node {
          intro
          introBeschreib {
            raw
          }
          pdf {
            file {
              url
              fileName
            }
          }
        }
      }
    }

    allContentfulHofladenArtikelBestellformular {
      edges {
        node {
          name
          price
          show
          description {
            raw
          }
        }
      }
    }
    site {
      siteMetadata {
        fullTitle
        description
        about
      }
    }
  }
`

export default IndexPage




// allContentfulHofladenBestellformularTexte {
//   edges {
//     node {
//       intro
//       introBeschreib {
//         raw
//       }
// titel2 {
//   raw
// }
// beschreib2 {
//   raw
// } 
//     }
//   }
// }


// allContentfulHofladenTexteAngebot {
//   edges {
//     node {
//       angebotTitle
//       angebotTitle2
//       angebotText {
//         raw
//       }
//      angebotText2 {
//         raw
//       }
//     }
//   }
// }