import React from "react"
import { Container, Row, Col } from "reactstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { contentOptions } from "../../utils/parser"

import "./index.scss"

const AktuellTab = ({
  allContentfulHofladenTexteAktuell,
  allContentfulHofladenGalerieAktuell,
}) => {
  return (
    <Container>
      <Row>
        <Col md="12" lg="12">
          <section className="about-content">
            {allContentfulHofladenTexteAktuell?.edges?.[0]?.node?.aktuellTitle && (
              <h2>{allContentfulHofladenTexteAktuell.edges[0].node.aktuellTitle}</h2>
            )}
              
            {allContentfulHofladenTexteAktuell?.edges?.[0].node?.aktuellText?.raw &&
              documentToReactComponents(JSON.parse(allContentfulHofladenTexteAktuell.edges[0].node.aktuellText.raw), contentOptions)}

            {allContentfulHofladenTexteAktuell?.edges?.[0]?.node?.aktuellTitle2 && (
              <h2>{allContentfulHofladenTexteAktuell.edges[0].node.aktuellTitle2}</h2>
            )}

            {allContentfulHofladenTexteAktuell?.edges?.[0].node?.aktuellText2?.raw &&
              documentToReactComponents(JSON.parse(allContentfulHofladenTexteAktuell.edges[0].node.aktuellText2.raw), contentOptions)}

            {allContentfulHofladenTexteAktuell?.edges?.[0]?.node?.aktuellTitle3 && (
              <h2>{allContentfulHofladenTexteAktuell.edges[0].node.aktuellTitle3}</h2>
            )}

            {allContentfulHofladenTexteAktuell?.edges?.[0].node?.aktuellText3?.raw &&
              documentToReactComponents(JSON.parse(allContentfulHofladenTexteAktuell.edges[0].node.aktuellText3.raw), contentOptions)}
          </section>
        </Col>
      </Row>
      <Row>
        {allContentfulHofladenGalerieAktuell?.edges?.map((image, idx) => {
          return (
            <Col lg={4} md={6} sm={12}>
              <div className="image-container">
                <div className="aspect-ratio">
                  <img src={image.node.bild.file.url} alt={image.node.bild.file.fileName} />
                </div>
                {image?.node?.title && (
                  <h4><b>{image.node.title}</b></h4>
                )}

                {image?.node?.beschreibung?.raw &&
                  documentToReactComponents(JSON.parse(image.node.beschreibung.raw), contentOptions)}
              </div>
              <br/>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default AktuellTab
